const AUTOMATIC = 'automatic'
const MANUAL = 'manual'

const SETUP_OPTIONS = [
  {
    text: 'Automatic',
    value: AUTOMATIC,
  },
  {
    text: 'Manual',
    value: MANUAL,
  },
]

const PROVIDER_OPTIONS = [
  {
    text: 'Azure',
    value: 'azure',
  },
]

const METADATA_KEYS = {
  IDP_ENTITYID: 'idp_entityid',
  IDP_SL_URL: 'idp_sl_url',
  IDP_SSO_URL: 'idp_sso_url',
  IDP_x509: 'idp_x509',
}

export { SETUP_OPTIONS, PROVIDER_OPTIONS, AUTOMATIC, MANUAL, METADATA_KEYS }
