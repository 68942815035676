<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-row class="padding-bottom-0">
      <v-col cols="12" md="6">
        <h3 class="py-5 primary--text">Single Sign-on</h3>
        <v-switch
          label="Single Sign-on"
          v-model="form.is_enable"
          :error-messages="form.$getError('is_enable')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-switch>

        <span class="red--text"><strong>* </strong></span>
        <label class="text-field-label">Select Identify Provider</label>
        <v-select
          label="Select Option"
          :items="providers"
          flat
          solo
          class="mt-2"
          v-model="form.provider"
          :error-messages="form.$getError('provider')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-select>

        <span class="red--text"><strong>* </strong></span>
        <label class="text-field-label">Select Default User Type</label>
        <v-select
          label="Select Option"
          :items="roles"
          flat
          solo
          class="mt-2"
          v-model="form.user_type"
          :error-messages="form.$getError('user_type')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-select>
        <p>New users will be assigned this User Type by default</p>

        <span class="red--text"><strong>* </strong></span>
        <label class="text-field-label">Service Provider Entity ID</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          placeholder="e.g. https://your-idm.com"
          v-model="form.sp_entity_id"
          :error-messages="form.$getError('sp_entity_id')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-text-field>

        <span class="red--text"><strong>* </strong></span>
        <label class="text-field-label">Adding Metadata</label>
        <div>
          <v-select
            label="Select Option"
            :items="ssoSetupOptions"
            flat
            solo
            class="mt-2"
            v-model="form.setup_type"
            :error-messages="form.$getError('setup_type')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-select>
        </div>

        <template v-if="form.setup_type">
          <template v-if="isAutomaticSetup()">
            <label class="text-field-label">Automatic Setup</label>
            <p>Upload your identity provider metadata XML File</p>
            <div class="mb-10 ml-1 row">
              <v-col cols="8" sm="5" md="5" lg="5">
                <v-file-input
                  hide-input
                  prepend-icon="Upload XML File"
                  class="v-btn v-btn--block v-btn--is-elevated v-btn--has-bg theme--light v-size--large primary upload-xml-file"
                  v-model="form.setup_file"
                  :error-messages="form.$getError('setup_file')"
                  :loading="form.$busy"
                  :disabled="form.$busy"
                  @change="uploadFile"
                />
                <ErrorMessage
                  v-if="form.$getError('setup_file')"
                  :error="form.$getError('setup_file')"
                  display="sentence"
                  class="file-multi-upload__error"
                  :style="{ marginTop: '8px', paddingLeft: '12px' }"
                />
              </v-col>
              <v-col v-if="this.form.setup_file" class="file-name-wrapper">
                <span>{{ getFileName() }}</span>
                <v-icon class="ml-2" @click="removeSetupFile">{{
                  icons.close
                }}</v-icon>
              </v-col>
            </div>
          </template>

          <template v-if="isManualSetup()">
            <label class="text-field-label">Manual Setup</label>
            <p>Manually enter your identity provider metadata XML details.</p>

            <span class="red--text"><strong>* </strong></span>
            <label class="text-field-label">Identity Provider Entity ID</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              placeholder="e.g. https://your-idm.com"
              v-model="form.idp_entityid"
              :error-messages="form.$getError('idp_entityid')"
              :loading="form.$busy"
              :disabled="form.$busy"
            ></v-text-field>

            <span class="red--text"><strong>* </strong></span>
            <label class="text-field-label">Identity Provider Login URL</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              placeholder="e.g. https://your-idm.com/login"
              v-model="form.idp_sso_url"
              :error-messages="form.$getError('idp_sso_url')"
              :loading="form.$busy"
              :disabled="form.$busy"
            ></v-text-field>

            <span class="red--text"><strong>* </strong></span>
            <label class="text-field-label">Identity Provider Logout URL</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              placeholder="e.g. https://your-idm.com/login"
              v-model="form.idp_sl_url"
              :error-messages="form.$getError('idp_sl_url')"
              :loading="form.$busy"
              :disabled="form.$busy"
            ></v-text-field>

            <span class="red--text"><strong>* </strong></span>
            <label class="text-field-label"
              >Identity Provider Certificate Public Key</label
            >
            <v-textarea
              flat
              solo
              required
              hide-details="auto"
              class="mt-2 mb-6"
              placeholder=""
              v-model="form.idp_x509"
              :error-messages="form.$getError('idp_x509')"
              :loading="form.$busy"
              :disabled="form.$busy"
            />
          </template>
        </template>

        <v-switch
          label="CMS Provision"
          v-model="form.is_cms_provision"
          :error-messages="form.$getError('is_cms_provision')"
          :loading="form.$busy"
          :disabled="form.$busy"
        ></v-switch>

        <div v-if="form.is_cms_provision">
          <label class="text-field-label">Tenant ID</label>
          <v-text-field
            flat
            solo
            class="mt-2"
            placeholder="Tenant ID"
            v-model="form.tenant_id"
            :error-messages="form.$getError('tenant_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Client ID</label>
          <v-text-field
            flat
            solo
            class="mt-2"
            placeholder="Client ID"
            v-model="form.client_id"
            :error-messages="form.$getError('client_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <label class="text-field-label">Client Secret</label>
          <v-text-field
            flat
            solo
            class="mt-2"
            placeholder="Client Secret"
            v-model="form.client_secret"
            :error-messages="form.$getError('client_secret')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>
        </div>

        <div class="mt-10">
          <v-btn
            type="submit"
            color="primary"
            class="mr-4 px-6"
            height="40px"
            width="100%"
            :loading="form.$busy"
            >{{ buttonLabel }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mdiClose } from '@mdi/js'
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import {
  SETUP_OPTIONS,
  PROVIDER_OPTIONS,
  MANUAL,
  AUTOMATIC,
  METADATA_KEYS,
} from '@/utils/enums/SsoSetupOption'
import ROLE from '@/utils/enums/Role'
import startCase from 'lodash/startCase'
import ErrorMessage from '@/components/fields/ErrorMessage'

export default {
  name: 'Organisation',
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  components: {
    ErrorMessage,
  },

  props: {
    provider: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      loading: false,
      form: new Form({
        is_enable: false,
        provider: '',
        user_type: '',
        setup_type: '',
        sp_entity_id: '',
        setup_file: null,
        idp_entityid: '',
        idp_sl_url: '',
        idp_sso_url: '',
        idp_x509: '',
        tenant_id: '',
        client_id: '',
        client_secret: '',
        is_cms_provision: false,
      }),
      icons: {
        close: mdiClose,
      },
    }
  },

  watch: {
    provider(newValue, oldValue) {
      this.initForm()
    },
  },

  computed: {
    ...mapState({
      createdSsoProvider: (state) => state.ssoProvider.ssoProviderDetails,
    }),

    buttonLabel() {
      return 'Save Changes'
    },

    submitApi() {
      return this.isUpdate ? this.updateSsoProvider : this.createSsoProvider
    },

    isUpdate() {
      return !!this.provider
    },

    ssoSetupOptions() {
      return SETUP_OPTIONS
    },

    providers() {
      return PROVIDER_OPTIONS
    },

    roles() {
      return Object.keys(ROLE).map((key) => {
        return {
          text: startCase(key),
          value: ROLE[key],
        }
      })
    },
  },

  methods: {
    ...mapActions({
      createSsoProvider: 'ssoProvider/createSsoProvider',
      updateSsoProvider: 'ssoProvider/updateSsoProvider',
    }),

    initForm() {
      if (this.provider) {
        this.form.is_enable = this.provider.is_enable
        this.form.provider = this.provider.provider
        this.form.setup_type = this.provider.setup_type
        this.form.sp_entity_id = this.provider.sp_entity_id
        this.form.user_type = this.provider.user_type
        this.form.idp_entityid = this.provider.metadata.IDP_ENTITYID
        this.form.idp_sl_url = this.provider.metadata.IDP_SL_URL
        this.form.idp_sso_url = this.provider.metadata.IDP_SSO_URL
        this.form.idp_x509 = this.provider.metadata.IDP_x509
        this.form.tenant_id = this.provider.tenant_id
        this.form.client_id = this.provider.client_id
        this.form.client_secret = this.provider.client_secret
        this.form.is_cms_provision = this.provider.is_cms_provision
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('Sso Provider details successfully updated!')
          } else {
            this.showSnackbar('Sso Provider successfully created!')
            this.$router.push({
              name: 'settings.organisation',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (!this.form.provider) {
        this.form.$setError('provider', 'Provider option is invalid.')
      }

      if (!this.form.user_type) {
        this.form.$setError('user_type', 'User Type option is invalid.')
      }

      if (!this.form.sp_entity_id) {
        this.form.$setError(
          'sp_entity_id',
          'Service Provider Entity ID is invalid.'
        )
      }

      if (!this.form.setup_type) {
        this.form.$setError('setup_type', 'Adding Metadata option is invalid.')
      } else {
        if (this.form.setup_type === AUTOMATIC) {
          if (!this.form.setup_file && !this.isMetadataExisted()) {
            this.form.$setError(
              'setup_file',
              'Metadata XML file is required for automatic option.'
            )
          }
        }

        if (this.form.setup_type === MANUAL) {
          if (!this.form.idp_entityid) {
            this.form.$setError(
              'idp_entityid',
              'Identity Provider Entity ID is invalid.'
            )
          }

          if (!this.form.idp_sso_url) {
            this.form.$setError('idp_sso_url', 'Login URL is invalid.')
          }

          if (!this.form.idp_sl_url) {
            this.form.$setError('idp_sl_url', 'Logout URL is invalid.')
          }

          if (!this.form.idp_x509) {
            this.form.$setError(
              'idp_x509',
              'Certificate Public Key is invalid.'
            )
          }
        }
      }

      return !this.form.$hasErrors()
    },

    getFormData() {
      const form = this.form.$data()

      if (form.setup_type === MANUAL) {
        form.metadata = {
          IDP_ENTITYID: form.idp_entityid,
          IDP_SL_URL: form.idp_sl_url,
          IDP_SSO_URL: form.idp_sso_url,
          IDP_x509: form.idp_x509,
        }
      }

      const formData = new FormData()

      Object.keys(form).forEach((key) => {
        const value = form[key]
        if (key === 'is_enable') {
          formData.append(key, value)
        } else if (key === 'is_cms_provision') {
          formData.append(key, value)
        } else if (key === 'metadata') {
          Object.keys(METADATA_KEYS).forEach((metaKey) => {
            formData.append(
              `metadata[${metaKey}]`,
              form[METADATA_KEYS[metaKey]]
            )
          })
        } else if (value) {
          formData.append(key, value)
        }
      })

      return formData
    },

    uploadFile(file) {
      this.form.setup_file = file
    },

    getFileName() {
      return this.form.setup_file ? this.form.setup_file.name : ''
    },

    removeSetupFile() {
      this.form.setup_file = null
    },

    isMetadataExisted() {
      return (
        this.provider &&
        this.provider.metadata &&
        this.provider.metadata.IDP_ENTITYID &&
        this.provider.metadata.IDP_SL_URL &&
        this.provider.metadata.IDP_SSO_URL &&
        this.provider.metadata.IDP_x509
      )
    },

    isAutomaticSetup() {
      return this.form.setup_type === AUTOMATIC
    },

    isManualSetup() {
      return this.form.setup_type === MANUAL
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-xml-file {
  border-radius: 6px;
  padding: 0 !important;
}
.file-name-wrapper {
  margin: auto 0;
}
::v-deep .upload-xml-file {
  .v-input__prepend-outer {
    width: 100%;
    height: 100%;
    margin-right: 0;

    .v-input__icon.v-input__icon--prepend {
      height: 100%;
    }
  }
  button.v-icon.v-icon--link {
    color: white;
    font-size: 1rem;
    width: 100%;
    min-height: 100%;
  }
}
</style>
