<template>
  <div class="mx-4" id="organisation-item-list">
    <div class="appbar mt-7 mb-3">
      <v-tabs>
        <v-tab disabled> General </v-tab>
        <v-tab> SSO </v-tab>
        <v-tab disabled> INTEGRATIONS </v-tab>
      </v-tabs>
    </div>
    <div class="ma-4">
      <SsoProvider :provider="provider" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import SsoProvider from './SsoProvider'

export default {
  name: 'Organisation',
  components: {
    SsoProvider,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  data() {
    return {
      loading: false,
      option: {},
    }
  },
  computed: {
    ...mapState({
      provider: (state) => state.ssoProvider.ssoProviderDetails,
    }),
  },

  mounted() {
    this.fetchSsoProvider()
  },

  methods: {
    ...mapActions({
      getSsoProviderDetails: 'ssoProvider/getSsoProviderDetails',
    }),

    async fetchSsoProvider() {
      await this.getSsoProviderDetails().catch((err) => {
        this.showSnackbar(this.getErrorMessage(err), false)
      })
    },
  },
}
</script>
